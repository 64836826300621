<template>
  <div ref="document">
    <div v-for="chouhyou in listChouhyou" v-bind:key="chouhyou.page" :aria-label="`${constData.chouhyouId}-${chouhyou.page}`">
      <div>
        <TemplateNormal :id="constData.chouhyouId + chouhyou.page" />
      </div>
    </div>
  </div>
</template>
<script>
import TemplateNormal from '@/assets/svg/tohoku_center/toshin_salesslip_details/normal.svg';
import { setPaperA4, splitMultiRowString, base64Decode, executeTransactSqls } from '@/assets/js/common';
import { ALERT_MESSAGE_COMMON_ERROR } from "@/assets/js/dialog_messages";
import { listV_CENTER_HACCHU_DATA_WITH_MEISAI, getM_SHOKISETTEI } from '@/graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import html2pdf from 'html2pdf.js'
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
export default {
  name: 'CenterToshinSalesSlipDetails',
  /* コンポーネント */
  components: {
    TemplateNormal
  },
  /* パラメータ */
  props:['params'],
  /* データ */
  data() {
    return {
      // 定数
      constData: {
        cntNormal: 39,
        chouhyouId: 'idChouhyou',
      },
      // ヘッダ
      menu_type: 'user',
      title: 'トシン発注明細（帳票）',
      // 表示帳票のサイズ（A4）
      // 以下のサイズで画面に表示されるように調整
      // ※height:297mmだと2ページ目にはみ出してしまうので微調整
      chouhyouSize: {
        width: '210mm',
        height: '295.01mm',
      },
      addProductCnt: 0,
      listChouhyou: [],
      replacementsCommon: [],

      // デコード・パースした引数
      parsedParams: null,
      // ページ数
      pageCount: 0,
    }
  },
  created() {
    // ヘッダ・フッタを非表示
    this.$store.commit("setShowNav", false);
  },
  /* マウント */
  mounted() {
    // ローディングON
    this.$store.commit("setLoading", true);

    // 印刷レイアウト設定
    setPaperA4();

    this.fetchData();
  },
  /* 関数群 */
  methods:{
    async fetchData(){
      // 指定された発注データを設定
      await this.setHacchuData();
    },
    /* 発注データ設定 */
    async setHacchuData(){
      // 引数をデコード
      // console.log(this.params);
      const decodedParams = await base64Decode(this.params);
      // console.log({decodedParams});
      // JSONパース
      this.parsedParams = JSON.parse(decodedParams);

      // WHERE句生成
      let whereOptions = this.parsedParams.whereOptions;

      // ORDER BY
      whereOptions += " ORDER BY SHIIRESAKI_CD, CHAKUBI_DATE, CHUMON_NO, MEISAI_NO";

      try {
        const result = await API.graphql(graphqlOperation(listV_CENTER_HACCHU_DATA_WITH_MEISAI,{where_options: whereOptions}));
        const orderData = result.data.listV_CENTER_HACCHU_DATA_WITH_MEISAI;
        // console.log({result});
        // console.log({orderData});
        if(orderData && orderData.length > 0){
          // 件数からどの帳票テンプレートを用いるか、何ページ用意するか等を設定
          await this.initListChouhyou(orderData.length);
          // 共通の置換文字列設定
          await this.createReplacementsCommon();
          // ページ毎の置換文字列設定
          await this.createReplacementsPage(orderData);
          // 作成した置換文字データをSVGファイルに設定
          // await this.setChouhyou();
          const chouhyouSet = await this.setChouhyou();

          if(chouhyouSet) {
            // PDF出力
            const result = await this.exportToPDF();
            // PDF出力に成功した場合フラグを更新
            if(result) {
              // console.log({result});
              await this.updateFlg(orderData);
            }
          }
        }
        // ローディングOFF
        this.$store.commit("setLoading", false);
      } catch (error) {
        // console.log(error);
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3014"]}, this.$route.name, 'setHacchuData', TYPE["0004"], error);
        // ローディングOFF
        this.$store.commit("setLoading", false);
        // アラート出力
        alert(ALERT_MESSAGE_COMMON_ERROR);
      }
    },
    /* 帳票リスト初期化 */
    async initListChouhyou(productCnt){
      // console.log('initListChouhyou');
      let productCntRemain = productCnt;
      let page = 1;
      while (productCntRemain > this.constData.cntNormal) {
        // 残りの製品件数が最終帳票の件数に収まらない場合、帳票を出力し続ける
        this.listChouhyou.push({page: page, replacements: []});
        productCntRemain -= this.constData.cntNormal;
        page++;
      }
      // 最終ページ
      this.listChouhyou.push({page: page, replacements: []});
      // 総ページ数を保持
      this.pageCount = page;
    },
    /* 共通の置換配列セット */
    async createReplacementsCommon(){
      // console.log('createReplacementsCommon');
      // 作成日付
      const now = new Date();
      const today = `${now.getFullYear()}年${now.getMonth()+1}月${now.getDate()}日`;
      this.replacementsCommon.push({key: '%依頼日%', value: today, textAnchor: 'end', textLength: 276});
    },
    /* 製品の置換配列セット */
    async createReplacementsPage(result){
      // console.log('createReplacementsPage');
      let productsIndex = 0;
      let whiteFlg = false;
      // console.log(`listChoiuhyou.length = ${ this.listChouhyou.length}`);

      for (let i = 0; i < this.listChouhyou.length; i++) {
        // SVGファイルの置換用文字列
        let replacements = [];
        /* ヘッダ */
          // 担当者名
          replacements.push({key: '%担当者名%', value: result[0].TANTOSHA_NAME ?? '', textAnchor: 'start', textLength: 176});
          // 営業所情報
          await this.setOfficesData(replacements);

        // 帳票毎に設定可能な製品の件数
        let productCntByChouhyou = this.constData.cntNormal;

        // 製品
        for (let j = 0; j < productCntByChouhyou; j++){
          // console.log({productsIndex});
          // console.log(`result.length = ${result.length}`);
          if(productsIndex < result.length){
            // 日付
            replacements.push({key: `%日付${j.toString()}%`, value: result[productsIndex].TOSHIN_DENPYO_DATE ?? '', textAnchor: 'end', textLength: 150});
            // 注文番号
            replacements.push({key: `%注${j.toString()}%`, value: result[productsIndex].CHUMON_NO ?? '', textAnchor: 'end', textLength: 95});
            // トシン伝票番号
            replacements.push({key: `%伝${j.toString()}%`, value: result[productsIndex].TOSHIN_DENPYO_NO ?? '', textAnchor: 'end', textLength: 95});
            // 仕入先名
            replacements.push({key: `%仕入${j.toString()}%`, value: result[productsIndex].SHIIRESAKI_NAME ?? '', textAnchor: 'start', textLength: 170});
            // 品番
            replacements.push({key: `%品番${j.toString()}%`, value: result[productsIndex].HINBAN_NO ?? '', textAnchor: 'start', textLength: 344});
            // 数量
            replacements.push({key: `%${j.toString()}%`, value: result[productsIndex].SURYO_SU ? `${result[productsIndex].SURYO_SU.toLocaleString()}` : '', textAnchor: 'end', textLength: 78});
            // 単価
            replacements.push({key: `%単${j.toString()}%`, value: result[productsIndex].NET_GK ? result[productsIndex].NET_GK.toLocaleString() : '', textAnchor: 'end', textLength: 111});
            // 金額
            if(result[productsIndex].SURYO_SU && result[productsIndex].NET_GK) {
              replacements.push({key: `%金額${j.toString()}%`, value: Math.ceil(result[productsIndex].SURYO_SU * result[productsIndex].NET_GK).toLocaleString(), textAnchor: 'end', textLength: 149});
            } else {
              replacements.push({key: `%金額${j.toString()}%`, value: 0, textAnchor: 'end', textLength: 149});
            }
            // 備考
            this.setSummary(replacements, j, result[productsIndex].BIKO);

            productsIndex++;
          } else {
            // 日付
            replacements.push({key: `%日付${j.toString()}%`, value: '', textAnchor: 'start', textLength: 150});
            // 注文番号
            replacements.push({key: `%注${j.toString()}%`, value: '', textAnchor: 'end', textLength: 95});
            // トシン伝票番号
            replacements.push({key: `%伝${j.toString()}%`, value: '', textAnchor: 'end', textLength: 95});
            // 仕入先名
            replacements.push({key: `%仕入${j.toString()}%`, value: '', textAnchor: 'start', textLength: 170});
            // 数量
            replacements.push({key: `%${j.toString()}%`, value: '', textAnchor: 'end', textLength: 78});
            // 備考
            for (let bikoIndex = 0; bikoIndex < 2; bikoIndex++) {
              replacements.push({key: `%備考${j.toString()}-${bikoIndex.toString()}%`, value: '', textAnchor: 'start', textLength: 245});
            }

            // 品番
            if (whiteFlg == true) {
              // console.log(`j = ${j}, result.length + 2 = ${result.length + 2}`)
              if (j == result.length + 1){
                // 合計出力
                // 単価
                replacements.push({key: `%単${j.toString()}%`, value: '合計', textAnchor: 'end', textLength: 111});
                // 金額合計
                let listPriceAmount = result.reduce(function(sum, item){
                  if(item.SURYO_SU && item.NET_GK) {
                    return sum + Math.ceil(item.SURYO_SU * item.NET_GK);
                  } else {
                    return sum + 0;
                  }
                }, 0);
                replacements.push({key: `%金額${j.toString()}%`, value: listPriceAmount.toLocaleString(), textAnchor: 'end', textLength: 149});
              } else {
                // 上記以外で以下余白を設定済み
                replacements.push({key: `%単${j.toString()}%`, value: '', textAnchor: 'start', textLength: 111});
                replacements.push({key: `%金額${j.toString()}%`, value: '', textAnchor: 'end', textLength: 149});
              }
              replacements.push({key: `%品番${j.toString()}%`, value: '', textAnchor: 'start', textLength: 344});
              // console.log(`空白 = ${j}`);
            } else {
              // 以下余白が入る箇所
              if ( i + 1 == this.listChouhyou.length && j + 1 == productCntByChouhyou ) {
                // 最終帳票、かつ、最終帳票の1行前の場合、余白がないため、「以上」とする。
                replacements.push({key: `%品番${j.toString()}%`, value: '以上', textAnchor: 'middle', textLength: 344});
                // console.log(`以上：j = ${j}, productCntByChouhyou = ${productCntByChouhyou}`);
              } else {
                // console.log(`i + 1 = ${i + 1}, this.listChouhyou.length = ${this.listChouhyou.length}, j + 1 = ${j + 1}, productCntByChouhyou = ${productCntByChouhyou}`);
                replacements.push({key: `%品番${j.toString()}%`, value: '以下余白', textAnchor: 'middle', textLength: 344});
                // console.log(`以下余白 = ${j}, productCntByChouhyou = ${productCntByChouhyou}`);
              }
              // 単価
              replacements.push({key: `%単${j.toString()}%`, value: '', textAnchor: 'end', textLength: 111});
              // 金額
              replacements.push({key: `%金額${j.toString()}%`, value: '', textAnchor: 'end', textLength: 149});

              whiteFlg = true;
            }
          }
        }
        // ページ番号
        replacements.push({key: '%ページ番号%', value: `${this.listChouhyou[i].page} / ${this.pageCount} ページ`, textAnchor: 'start', textLength: 196});

        this.listChouhyou[i].replacements = replacements;
      }
    },
    /* 置換文字列に営業所情報を設定 */
    async setOfficesData(replacements) {
      // 部門コード
      const user = this.$store.getters.user;
      const bumonCd = user.BUMON_CD;

      // 営業所データ取得
      let result = await API.graphql(graphqlOperation(getM_SHOKISETTEI, {BUMON_CD: bumonCd}));
      // console.log({result});
      const bumon = result.data.getM_SHOKISETTEI;
      // console.log({bumonData});
      // // TODO データが存在しなかった場合の処理
      // const bumon = bumonData[0];
      // console.log({bumon});

      // 部門名称
      replacements.push({key: '%部門名%', value: bumon.BUMON_NAME ?? '', textAnchor: 'start', textLength: 246});
      // TEL
      replacements.push({key: '%TEL%', value: bumon.DENWA_NO ?? '', textAnchor: 'start', textLength: 236});
      // FAX
      replacements.push({key: '%FAX%', value: bumon.FAX_NO ?? '', textAnchor: 'start', textLength: 236});
    },
    /* 置換文字列に備考を設定 */
    async setSummary(replacements, rowNum, summary) {
      let arySummary = splitMultiRowString(summary, 25);
      for (let i = 0; i < 2; i++) {
        if (i < arySummary.length) {
          // 備考
          replacements.push({key: `%備考${rowNum}-${i.toString()}%`, value: arySummary[i], textAnchor: 'start', textLength: 245});
        } else {
          // 備考
          replacements.push({key: `%備考${rowNum}-${i.toString()}%`, value: '', textAnchor: 'start', textLength: 245});
        }
      }
    },
    /* 帳票に各種値セット */
    async setChouhyou(){
      // console.log('setChouhyou');
      for (let i = 0; i < this.listChouhyou.length; i++){
        // console.log(`this.constData.chouhyouId + this.listChouhyou[i].page = ${this.constData.chouhyouId + this.listChouhyou[i].page}`);
        let svgDoc = document.getElementById(this.constData.chouhyouId + this.listChouhyou[i].page);
        this.setReplacements(svgDoc, this.listChouhyou[i].replacements);
        await this.setSize(svgDoc);
      }
      return true;
    },
    /* 置換値をSVGファイルに設定 */
    setReplacements(node, replacements){
      // console.log('setReplacements');
      for (const textElement of node.getElementsByTagName('text')) {
        for (const child of textElement.children) {
          if(child.tagName == 'tspan'){
            for (const commonReplacement of this.replacementsCommon) {
              if(child.innerHTML.indexOf(commonReplacement.key) != -1){
                this.setTspan(textElement, child, commonReplacement);
                break;
              }
            }
            for(let i = 0; i < replacements.length; i++){
              if(child.innerHTML.indexOf(replacements[i].key) != -1){
                this.setTspan(textElement, child, replacements[i]);
                replacements.splice(i, 1);
                break;
              }
            }
          }
        }
      }
      for (const gElement of node.getElementsByTagName('g')) {
        this.setReplacements(gElement, replacements);
      }
    },
    /* Textタグ内のテキストを設定 */
    async setTspan(tagText, tagTspan, config){
      // 文字を置換
      tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value);
      /* 最大長を設定（最大長を超過する場合、自動で縮小） */
      if(tagText.getBBox().width > config.textLength){
        tagTspan.setAttribute('textLength', config.textLength);
        tagTspan.setAttribute('lengthAdjust', 'spacingAndGlyphs');
      }
      let colX = parseFloat(tagTspan.getAttribute('x'));
      /* 中央寄せ、右寄せを設定 */
      // 中央寄せ
      if(config.textAnchor == 'middle'){
        tagTspan.setAttribute('x', colX + config.textLength / 2);
      }
      // 右寄せ
      if(config.textAnchor == 'end'){
        tagTspan.setAttribute('x', colX + config.textLength);
      }
      tagTspan.setAttribute('text-anchor', config.textAnchor);
    },
    /* 印鑑を設定 */
    async setStamp(tagTspan, config){
      // 文字を置換
      tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value);
      // 最大長を設定（最大長を超過する場合、自動で縮小）
      tagTspan.setAttribute('textLength', config.textLength);
      tagTspan.setAttribute('lengthAdjust', 'spacingAndGlyphs');
      // 座標取得
      let colX = parseFloat(tagTspan.getAttribute('x'));
      // 中央寄せ
      tagTspan.setAttribute('x', colX + config.textLength / 2);
      tagTspan.setAttribute('text-anchor', config.textAnchor);
      // 縦書きを設定（また、文字間隔を狭くする）
      tagTspan.setAttribute('writing-mode', 'tb');
      tagTspan.setAttribute('letter-spacing', -5);
    },
    /* 取得結果セット */
    async setSize(svgDoc){
      // viewBoxに元のサイズを設定
      const zoomedViewBox = [0, 0, svgDoc.clientWidth, svgDoc.clientHeight].join(' ');
      svgDoc.setAttribute('viewBox', zoomedViewBox);
      // 横幅と高さをパラメータで指定したサイズに修正
      svgDoc.setAttribute('width', this.chouhyouSize.width);
      svgDoc.setAttribute('height', this.chouhyouSize.height);
    },
    /* 帳票をPDFダウンロード */
    exportToPDF() {
      try {
        const pages = Array.from(document.querySelectorAll(`div[aria-label^="${this.constData.chouhyouId}-"]`));

        let worker = html2pdf()
          .set({
            filename: 'トシン伝票明細.pdf',
            image: {type: 'jpeg', quality: 0.55},
            html2canvas: { scale: 2 },
          })
          .from(pages[0])

        if (pages.length > 1) {
          // jsPDFインスタンスを生成
          worker = worker.toPdf()

          // 1ページずつPDFをレンダリング
          for (const page of pages.slice(1)) {
            worker = worker
              .get('pdf')
              .then(pdf => {
                pdf.addPage()
              })
              .from(page)
              .toContainer()
              .toCanvas()
              .toPdf()
          }
        }

        worker = worker.save()
        return true;
      } catch (error) {
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3014"]}, this.$route.name, 'exportToPDF', TYPE["0003"], error);
        return false;
      }
    },
    /* トシン伝票印刷フラグ・トシン伝票印刷日付更新 */
    async updateFlg(orders) {
      // console.log('updateFlg');

      // console.log({orders});
      // トシン伝票印刷フラグ・トシン伝票印刷日付を更新する
      let updateSQL = '';
      updateSQL += 'UPDATE T_CENTER_HACCHU_MEISAI_DATA SET';
      updateSQL += ' TOSHIN_DENPYO_INSATSU_FLG = 1,';
      updateSQL += ' TOSHIN_DENPYO_INSATSU_DATE = CURDATE()';

      // 発注データIDを抜き出し
      const originalHacchuDataIdList = orders.map(function(element) {
        return element.HACCHU_DATA_ID;
      });
      // 重複を削除してIN句の文字列を作成
      const hacchuDataIdList = Array.from(new Set(originalHacchuDataIdList))
      const hacchuDataIds = hacchuDataIdList.join(',');

      updateSQL += ` WHERE HACCHU_DATA_ID IN (${hacchuDataIds})`;
      updateSQL += ` AND TOSHIN_DENPYO_DATE IS NOT NULL AND TOSHIN_DENPYO_INSATSU_FLG = 0 AND TOSHIN_DENPYO_INSATSU_DATE IS NULL`;
      console.log({updateSQL});
      if (!(await executeTransactSqls(this.$route.name, 'updateFlg', updateSQL))) {
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        return;
      }
    },
  },
}
</script>
<style>
  body
  {
    margin: 0;
    padding: 0;
    text-align: center;
  }
</style>